import React, { useState, useEffect, useReducer, useContext, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/system';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import Grid from '../../components/Grid/Grid';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import PaperContainer from '../../components/PaperContainer/PaperContainer';
import SelectAutoComplete from '../../components/SelectField/SelectAutoComplete';
import ReadonlyField from '../../components/ReadonlyField/ReadonlyField';
import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import IconButton from '../../components/IconButton/IconButton';
import Radio from '../../components/Radio/Radio';
import checkboxReducer, { checkboxActions } from '../../reducers/checkboxReducer';
import { DialogContext } from '../../contexts/DialogContext';
import useAxios from '../../hooks/useAxios';
import { classType, manufactureType } from '../../constants/enums';
import { dialogActions } from '../../reducers/dialogReducer';
import useCancelToken from '../../hooks/useCancelToken';
import regex from '../../constants/validationRegex';
import StorageQtyTable from './StorageQtyTable';
import Divider from '../../components/Divider/Divider';
import useFormingType, { useFormingTypeToEnum } from '../../hooks/useFormingType';

const TransActions = {
  CREATE: 'CREATE',
  FETCH: 'FETCH',
  STORAGE: 'STORAGE',
};

const useStyles = makeStyles()((theme) => ({
  container: {
    marginBottom: theme.spacing(1.5),
  },
  header: {
    marginTop: theme.spacing(2.5),
  },
  headerBtn: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(4),
  },
  subContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  field: {
    width: 'calc(50% - 80px)',
    maxWidth: 330,
    marginRight: 80,
    marginBottom: theme.spacing(3),
  },
  field_sm: {
    [theme.breakpoints.up('lg')]: {
      width: 'calc(24.5% - 80px)',
      marginRight: 20,
    },
    [theme.breakpoints.up('xl')]: {
      width: 'calc(23% - 80px)',
    },
    width: 'calc(28% - 80px)',
    maxWidth: 290,
    marginRight: 12,
    marginBottom: theme.spacing(3),
  },
  fieldWithBtn: {
    flexWrap: 'nowrap',
    width: 'calc(50% - 40px)',
    maxWidth: 370,
    marginRight: 80,
    [theme.breakpoints.down('xl')]: {
      marginRight: 0,
    },
    marginBottom: theme.spacing(3),
  },
  fieldWithBtnHover: {
    '&:hover': {
      background: 'none',
    },
  },
  confirm: {
    marginTop: theme.spacing(3),
    marginLeft: -16,
  },
  radio: {
    marginRight: 0,
    '&:not(:last-of-type)': {
      marginRight: 4,
    },
    '& .MuiRadio-root': { marginRight: 0 },
  },
  error: {
    marginBottom: theme.spacing(2.5),
  },
  dashed_divider: {
    margin: '24px -26px',
    background: `repeating-linear-gradient(90deg,${alpha(theme.palette.common.black, 0.2)},${alpha(
      theme.palette.common.black,
      0.2
    )} 6px,transparent 6px,transparent 12px)`,
    border: 'none',
    height: 1,
  },
  writeOff_btn: {
    marginRight: '12px !important',
  },
}));

const TransCreate = () => {
  const [transAction, setTransAction] = useState(TransActions.CREATE);
  const [transId, setTransId] = useState('');
  const [transIdErr, setTransIdErr] = useState(false);
  const [workNo, setWorkNo] = useState('');
  const [workNoErr, setWorkNoErr] = useState(false);
  const [workData, setWorkData] = useState(null);
  const [errorMsg, setErrorMsg] = useState('請正確填寫工令單號');
  const [showSetCustomer, setShowSetCustomer] = useState(false);
  const [isSettingCustomer, setIsSettingCustomer] = useState(false);
  const [havePrivilege, setHavePrivilege] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState({});
  const [customer, setCustomer] = useState({});
  const [formingType, setFormingType] = useState({});
  const [selectedFormingType, setSelectedFormingType] = useState(manufactureType.FORMING);
  const [storageList, setStorageList] = useState([]);
  const [openStorageQty, setOpenStorageQty] = useState(false);
  const [prodWeight, setProdWeight] = useState(null);
  const [currentProdWeightQty, setCurrentProdWeightQty] = useState('');
  const [storageInfo, setStorageInfo] = useState({
    workId: '', // 轉入工令
    qty: 0, // 工令總數
    elNo: '', // 暫存工令
    belongQty: 0, // 掛帳工令總數
    formingQty: 0, // 已壓型總數
    drillAlignQty: 0, // 鑽孔排列總數
    wasteQty: 0, // 損耗總數
  });
  const customerTimes = useRef(0);
  const [shiftList, shiftDispatch] = useReducer(checkboxReducer, []);
  const { dialogDispatch } = useContext(DialogContext);
  const { newCancelToken } = useCancelToken();
  const { classes } = useStyles();

  const toggleAction = (action) => {
    setTransAction(action);
    setTransId('');
    setWorkNo('');
    setWorkData(null);
    shiftDispatch({ type: checkboxActions.RESET });
    setOpenStorageQty(false);
    setStorageList([]);
  };

  const resetSelectedCustomerData = () => {
    setShowSetCustomer(false);
    setCustomer({});
    setCustomerList([]);
    setDefaultCustomer({});
  };

  const handleChange = (e) => {
    if (workNoErr) {
      setWorkNoErr(false);
    }
    setWorkNo(e.target.value);
  };

  const handleTransIdChange = (e) => {
    if (transIdErr) {
      setTransIdErr(false);
    }
    setTransId(e.target.value);
  };

  const validationWorkNo = () => {
    if (workNo.length === 0) {
      setWorkNoErr(true);
      return false;
    }
    return true;
  };

  const getCustomerList = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/customerDataSorted',
      {},
      true,
      cancelToken
    );
    if (status) {
      setCustomerList(
        result.customer.map((item) => ({
          id: item.customerId,
          value: item.customerId,
          name: item.customerAlias
            ? `${item.customerAlias} | ${item.customerName}`
            : item.customerName,
        }))
      );
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '無法取得客戶資訊',
          message: noPrivilegeMsg || result,
        },
      });
    }
  }; // getCustomerList

  const handleFetch = async () => {
    resetSelectedCustomerData();
    if (!validationWorkNo()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/originSc',
      { scOdno: workNo.trim() },
      true,
      cancelToken
    );
    shiftDispatch({
      type: checkboxActions.RESET,
    });
    if (status) {
      setWorkNoErr(false);
      const {
        sc: {
          elNo,
          customerId,
          customerName,
          customerAlias,
          workId,
          materialName,
          prodName,
          scFdat,
          odRedat,
          prodShade,
          manufactureType: formingManufactureType,
          scQty,
          prodPlaning: {
            createdTotalQty,
            transferQty,
            defectQty,
            formingInventoryQty,
            receiveFromInventoryQty,
          },
        },
        boQty: { boQtyShowUpdate, newestBoQty, scBoQty },
        writeOff,
      } = result;

      setFormingType(useFormingType(formingManufactureType));
      setWorkData({
        elNo,
        workId,
        materialName: materialName || '',
        customerId: customerId || '',
        customerAlias: customerAlias || '',
        customerName: customerName || '',
        productName: prodName || '',
        scFDate: scFdat,
        odReDate: odRedat,
        prodShade,
        scQty,
        normalFormingQty: createdTotalQty,
        otherFormingQty: transferQty,
        defectQty,
        storageQty: formingInventoryQty,
        belongQty: receiveFromInventoryQty, // 轉移至該工令的壓型數量
        writeOff, // 是否已經被銷帳
      });
      setProdWeight({
        qty: scBoQty || '',
        newQty: newestBoQty || '',
        isUpdate: boQtyShowUpdate || false,
      });
      setCurrentProdWeightQty(scBoQty || '');
      setCustomer(
        customerId || customerAlias
          ? {
              customerId,
              customerAlias,
            }
          : {}
      );
      setDefaultCustomer({
        customerId: customerId || '',
        customerAlias: customerAlias || '',
      });
      if ('hasRightSettingSCustomer' in result) {
        setShowSetCustomer(true);
        setHavePrivilege(result.hasRightSettingSCustomer);
        getCustomerList();
      }
    } else {
      setWorkData(null);
      setWorkNoErr(true);
      if (rtCode === 'E97') {
        setErrorMsg(result);
        return;
      }
      setErrorMsg(noPrivilegeMsg || '請正確填寫工令單號');
    }
  };

  const handleCustomerChange = (data) => {
    if (!data) {
      setCustomer({});
      setWorkData((prev) => ({
        ...prev,
        customerId: defaultCustomer.customerId,
        customerAlias: defaultCustomer.customerAlias,
      }));
      return;
    }
    const { id, name } = data;
    setCustomer({
      customerId: id,
      customerAlias: name,
    });
    setWorkData((prev) => ({
      ...prev,
      customerId: id,
      customerAlias: name,
    }));
  };

  const handleCustomerConfirm = (state) => {
    if (!havePrivilege) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '您尚無此功能之權限',
          message: '目前無法修改客戶，請稍後再試，或請聯絡網管人員！',
        },
      });
      return;
    }
    setIsSettingCustomer(!state);
    if (
      !state &&
      (defaultCustomer.customerAlias || defaultCustomer.customerId) &&
      customerTimes.current === 0
    ) {
      // eslint-disable-next-line no-plusplus
      customerTimes.current++;
      setCustomer({});
    }
  };

  const updateProdWeight = async (newQty) => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/updateBoQty',
      { workId: workData.workId, boQty: newQty },
      true,
      cancelToken
    );
    if (status) {
      handleFetch();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '單重更新失敗',
          message: '單重更新失敗，請稍後再試，或通知網管人員！',
        },
      });
    }
  };

  const handleUpdateProdWeight = () => {
    if (!prodWeight.isUpdate) return;
    if (!prodWeight.newQty) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '單重更新失敗',
          message: '單重尚未設定，請通知管理人員設定單重；完成單重設定後始可建立該單重之移轉單。',
        },
      });
      return;
    }
    dialogDispatch({
      type: dialogActions.OPEN,
      config: {
        title: '是否確定要更新？',
        message: `您是否確定要更新？原始單重為 ${currentProdWeightQty || '空'}，將更改為 ${
          prodWeight.newQty
        }。`,
        handleConfirm: () => updateProdWeight(prodWeight.newQty),
      },
    });
  };

  const validationConfirm = () => {
    if (workData.customerAlias.length === 0) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料設定有誤',
          message:
            workNo.includes('S') || workNo.includes('Q')
              ? '該料號之客戶尚未設定，請通知管理人員設定料號之相關設定。或者自行於此畫面針對此工令設定客戶，設定後始可建立移轉單。'
              : '客戶簡稱尚未設定，請通知管理人員設定客戶簡稱；完成客戶簡稱設定後始可建立該客戶之移轉單。',
        },
      });
      return false;
    }
    if (workData.materialName.length === 0) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料設定有誤',
          message:
            '該料號之材質尚未設定，請通知管理人員設定料號之相關設定；完成材質設定後始可建立該料號之移轉單。',
        },
      });
      return false;
    }
    if (formingType.value === manufactureType.NONE) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料設定有誤',
          message:
            '該料號之製造類別尚未設定，請通知管理人員設定料號之相關設定；完成製造類別設定後可建立該料號之移轉單。',
        },
      });
      return false;
    }
    if (!currentProdWeightQty) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '資料設定有誤',
          message:
            '該單重尚未設定，請通知管理人員設定單重之相關設定；完成單重設定後始可建立該單重之移轉單。',
        },
      });
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    if (!validationConfirm()) return;
    let transClass = '';
    shiftList
      .filter((item) => item.checked)
      .forEach((item) => {
        transClass += `${item.name}/`;
      });
    const { workId, prodShade, customerId } = workData;
    const cancelToken = newCancelToken();
    const data = {
      sc: {
        workId,
        transClass,
        prodShade,
        scOdno: workNo.trim(),
        customerId,
        manufactureType:
          formingType.value === manufactureType.NONE
            ? formingType.NONE
            : useFormingTypeToEnum(selectedFormingType),
      },
    };
    if (formingType.value !== manufactureType.FORMING) {
      data.sc.receiptType = useFormingType(selectedFormingType).id;
    }
    if (currentProdWeightQty) {
      data.sc.boQty = currentProdWeightQty;
    }
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/createTrans',
      data,
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      window.open(fileUrl, '_blank');
      setWorkData(null);
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '建立移轉單失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  const validationTransId = () => {
    if (transId.length === 0) {
      setTransIdErr(true);
      return false;
    }
    if (
      !regex.FORMING_TRANS_ID.test(transId) &&
      !regex.ALIGNING_TRANS_ID.test(transId) &&
      !regex.DRILLING_TRANS_ID.test(transId) &&
      !regex.SINTERING_TRANS_ID.test(transId) &&
      !regex.GRINDER_TRANS_ID.test(transId)
    ) {
      setTransIdErr(true);
      return false;
    }
    return true;
  };

  const handleDownload = async () => {
    if (!validationTransId()) return;
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/getTransPdf',
      { transId },
      true,
      cancelToken
    );
    if (status) {
      const { fileUrl } = result;
      window.open(fileUrl, '_blank');
      setTransId('');
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '下載移轉單失敗！',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  // 取得暫存工令列表
  const handleStorageFetch = async () => {
    if (workNo.length <= 0) {
      setWorkNoErr(true);
      return;
    }
    setWorkNoErr(false);
    setStorageList([]);
    setOpenStorageQty(false);
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/findInventoryList',
      { scOdno: workNo.trim() },
      true,
      cancelToken
    );
    if (status) {
      const {
        receiveFromInventoryQty,
        formingQty,
        drillAlignQty,
        wasteQty,
        inventoryList,
        elNo,
        scQty,
      } = result;
      setOpenStorageQty(true);
      setStorageList(
        inventoryList.map((inventory) => ({
          workNo: inventory.scOdno, // 原始工令單號
          workId: inventory.srcWorkId, // 原始 workId
          elNo: inventory.elNo, // 暫存料號
          qty: inventory.newQuantity, // 暫存數量
          selectedQty: '',
          disabled: true, // 預設都是不可以輸入的
        }))
      );
      setStorageInfo({
        workId: workNo.trim(),
        qty: scQty,
        elNo,
        belongQty: receiveFromInventoryQty, // 掛帳工令總數
        formingQty, // 已壓型總數
        drillAlignQty, // 鑽孔排列總數
        wasteQty, // 損耗總數
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '取得暫存工令失敗',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  const handleConfirmWriteOff = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/writeOff',
      { workId: workData.workId },
      true,
      cancelToken
    );
    if (status) {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '銷帳成功',
          message: `已成功將${workNo.trim()}銷帳`,
        },
      });
      handleFetch();
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '銷帳失敗',
          message: noPrivilegeMsg || result || '目前無法銷帳，請稍後再試，或請聯絡網管人員！',
        },
      });
    }
  };

  const handleWriteOff = async () => {
    const cancelToken = newCancelToken();
    const [status, result, rtCode, noPrivilegeMsg] = await useAxios(
      '/checkWriteOff',
      { workId: workData.workId },
      true,
      cancelToken
    );
    if (status) {
      // 多壓型的數量 = 已壓型總數 + 掛帳工令總數 - 鑽孔/排列總數 - 損耗總數
      // writeOffQuantity = workData.normalFormingQty + workData.belongQty -  workData.otherFormingQty - workData.defectQty
      const { writeOffQuantity } = result;
      const confirmText =
        '已銷帳的工令將不得建立移轉單，也不得更新壓型／鑽孔／排列移轉單的移轉數量，也不得更新該工令申報的損耗數量。';

      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          title: '是否確定要銷帳？',
          message: writeOffQuantity ? (
            <p>
              請確認是否將多壓型的{writeOffQuantity}掛帳於暫存工令{workData.elNo}之下。
              <br />
              {confirmText}
            </p>
          ) : (
            <p>{confirmText}</p>
          ),
          handleConfirm: () => handleConfirmWriteOff(),
        },
      });
    } else {
      dialogDispatch({
        type: dialogActions.OPEN,
        config: {
          singleBtn: true,
          title: '銷帳失敗',
          message: noPrivilegeMsg || result,
        },
      });
    }
  };

  useEffect(() => {
    shiftDispatch({
      type: checkboxActions.INIT,
      init: [
        {
          name: classType.CLASS_MORNING,
          label: '早班',
          checked: false,
        },
        {
          name: classType.CLASS_NOON,
          label: '中班',
          checked: false,
        },
        {
          name: classType.CLASS_NIGHT,
          label: '晚班',
          checked: false,
        },
      ],
    });
  }, []);

  return (
    <>
      <Grid container>
        <PageTitle title="建立移轉單" />
        <PaperContainer className={classes.container}>
          <Typography variant="h6">查詢工令資訊</Typography>
          <Grid container alignItems="start" spacing={2} className={classes.header}>
            <Typography variant="body1" style={{ margin: '2.5px 8px 0' }}>
              新建移轉單或重新下載？
            </Typography>
            <Button
              variant="text"
              color={transAction === TransActions.CREATE ? 'primary' : 'grey'}
              onClick={() => toggleAction(TransActions.CREATE)}
              customClasses={classes.headerBtn}
            >
              建立新移轉單
            </Button>
            <Button
              variant="text"
              color={transAction === TransActions.FETCH ? 'primary' : 'grey'}
              onClick={() => toggleAction(TransActions.FETCH)}
            >
              下載已建立移轉單
            </Button>
            <Button
              variant="text"
              color={transAction === TransActions.STORAGE ? 'primary' : 'grey'}
              onClick={() => toggleAction(TransActions.STORAGE)}
            >
              暫存工令移轉
            </Button>
          </Grid>
          {transAction === TransActions.CREATE && (
            <Grid container alignItems="flex-end" spacing={2} className={classes.content}>
              <Grid item xs={5}>
                <InputField
                  type="text"
                  id="workNo"
                  name="workNo"
                  placeholder="請輸入工令單號"
                  label="工令單號"
                  value={workNo}
                  errormsg={errorMsg}
                  onChange={handleChange}
                  error={workNoErr}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleFetch}
                  customClasses={workNoErr && classes.error}
                >
                  查詢
                </Button>
              </Grid>
            </Grid>
          )}
          {transAction === TransActions.FETCH && (
            <Grid container alignItems="flex-end" mt={3} spacing={2}>
              <Grid item xs={5}>
                <InputField
                  type="text"
                  id="transId"
                  name="transId"
                  placeholder="請輸入移轉單號"
                  label="移轉單號"
                  value={transId}
                  errormsg="請正確填寫移轉單號"
                  onChange={handleTransIdChange}
                  error={transIdErr}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<GetAppRoundedIcon />}
                  onClick={handleDownload}
                  customClasses={transIdErr && classes.error}
                >
                  下載
                </Button>
              </Grid>
            </Grid>
          )}
          {transAction === TransActions.STORAGE && (
            <Grid container alignItems="flex-end" spacing={2} className={classes.content}>
              <Grid item xs={5}>
                <InputField
                  label="轉入正式工令單號"
                  placeholder="請輸入轉入正式工令單號"
                  value={workNo}
                  onChange={handleChange}
                  error={workNoErr}
                  errormsg="請輸入正確的轉入正式工令單號"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<SearchRoundedIcon />}
                  onClick={handleStorageFetch}
                  customClasses={workNoErr && classes.error}
                >
                  查詢
                </Button>
              </Grid>
            </Grid>
          )}
        </PaperContainer>
        {workData && (
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={9}>
              <PaperContainer>
                <Typography variant="h6">工令資訊</Typography>
                <Grid container className={classes.content} spacing={3}>
                  <Grid item xs={3}>
                    <ReadonlyField label="料號" value={workData.elNo} name="elNo" />
                  </Grid>
                  {!showSetCustomer ? (
                    <Grid item xs={3}>
                      <ReadonlyField
                        label="客戶"
                        value={workData.customerAlias || workData.customerName}
                        name="customer"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={3} style={{ display: 'flex' }}>
                      <>
                        <SelectAutoComplete
                          label="客戶"
                          name="customer"
                          placeholder="請選擇客戶"
                          value={
                            !isSettingCustomer
                              ? customer.customerAlias?.split('|')[0]
                              : customer.customerAlias
                          }
                          handleChange={handleCustomerChange}
                          selections={customerList}
                          id="customer"
                          readOnly={!isSettingCustomer}
                          readOnlyStyles={
                            !isSettingCustomer && {
                              '& .MuiInputBase-root.MuiInput-root.MuiAutocomplete-inputRoot': {
                                paddingTop: '18px',
                                paddingBottom: '4px',
                                paddingLeft: '6px',
                                borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                                '&:before': {
                                  display: 'none',
                                },
                                '&:after': {
                                  display: 'none',
                                },
                              },
                            }
                          }
                          style={{ maxHeight: '53px' }}
                        />
                        <IconButton
                          aria-label="modify"
                          color="primary"
                          className={classes.fieldWithBtnHover}
                          style={
                            !isSettingCustomer
                              ? {
                                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                                  borderRadius: 0,
                                }
                              : {}
                          }
                          onClick={() => handleCustomerConfirm(isSettingCustomer)}
                        >
                          {!isSettingCustomer ? <CreateIcon /> : <CheckIcon />}
                        </IconButton>
                      </>
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <ReadonlyField label="材質" value={workData.materialName} name="materialName" />
                  </Grid>
                  <Grid item xs={3} style={{ display: 'flex' }}>
                    <ReadonlyField label="單重" value={currentProdWeightQty} name="prodWeight" />
                    {prodWeight.isUpdate && (
                      <IconButton
                        aria-label="modify"
                        color="primary"
                        className={classes.fieldWithBtnHover}
                        style={{
                          borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
                          borderRadius: 0,
                        }}
                        onClick={handleUpdateProdWeight}
                      >
                        <RestartAltIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <ReadonlyField
                      label="產品名稱"
                      value={workData.productName}
                      name="productName"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ReadonlyField label="開工日期" value={workData.scFDate} name="scFDate" />
                  </Grid>
                  <Grid item xs={3}>
                    <ReadonlyField label="出貨日期" value={workData.odReDate} name="odReDate" />
                  </Grid>
                </Grid>
                <Divider className={classes.dashed_divider} />
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <ReadonlyField label="工令總數" value={workData.scQty} name="scQty" />
                  </Grid>
                  <Grid item xs={3}>
                    <ReadonlyField
                      label="已壓型總數"
                      value={workData.normalFormingQty}
                      name="normalFormingQty"
                    />
                  </Grid>
                  {formingType.value !== manufactureType.FORMING && (
                    <>
                      <Grid item xs={3}>
                        <ReadonlyField
                          label="鑽孔/排列總數"
                          value={workData.otherFormingQty}
                          name="otherFormingQty"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ReadonlyField
                          label="損耗總數"
                          value={workData.defectQty}
                          name="defectQty"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ReadonlyField
                          label="暫存工令總數"
                          value={workData.storageQty}
                          name="storageQty"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ReadonlyField
                          label="掛帳工令總數"
                          value={workData.belongQty}
                          name="belongQty"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </PaperContainer>
            </Grid>
            <Grid item xs={3}>
              <PaperContainer>
                <Typography variant="h6">選取生產班別</Typography>
                <Grid container className={classes.content}>
                  <CheckboxGroup itemList={shiftList} checkboxDispatch={shiftDispatch} gap={1} />
                </Grid>
                {formingType.value !== manufactureType.FORMING &&
                  formingType.value !== manufactureType.NONE && (
                    <>
                      <Typography variant="h6" className={classes.subContent}>
                        壓型加工
                      </Typography>
                      <Grid container xs={8} justifyContent="space-between">
                        <Radio
                          label="壓型"
                          name="壓型"
                          value={manufactureType.FORMING}
                          selectedValue={selectedFormingType}
                          onChange={(e) => setSelectedFormingType(e.target.value)}
                          className={classes.radio}
                        />
                        <Radio
                          label={formingType.name}
                          name={formingType.name}
                          value={formingType.value}
                          selectedValue={selectedFormingType}
                          onChange={(e) => setSelectedFormingType(e.target.value)}
                          className={classes.radio}
                        />
                      </Grid>
                    </>
                  )}
              </PaperContainer>
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="start"
              justifyContent="flex-end"
              className={classes.confirm}
            >
              <Button
                variant="contained"
                color="error"
                customClasses={classes.writeOff_btn}
                rounded
                startIcon={<DoDisturbIcon />}
                onClick={handleWriteOff}
                disabled={workData.writeOff}
              >
                {workData.writeOff ? '已銷帳' : '銷帳'}
              </Button>
              {(shiftList[0].checked || shiftList[1].checked || shiftList[2].checked) &&
              !isSettingCustomer ? (
                <Button
                  variant="contained"
                  color="primary"
                  rounded
                  startIcon={<CheckRoundedIcon />}
                  onClick={handleConfirm}
                >
                  確認
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  rounded
                  startIcon={<CheckRoundedIcon />}
                  onClick={handleConfirm}
                  disabled
                >
                  確認
                </Button>
              )}
            </Grid>
          </Grid>
        )}

        {openStorageQty && (
          <StorageQtyTable
            storageInfo={storageInfo}
            storageList={storageList}
            handleGoToTransCreate={() => toggleAction(TransActions.CREATE)}
          />
        )}
      </Grid>
    </>
  );
};

export default TransCreate;
